import {atom, SetterOrUpdater} from 'recoil';
import {modalTypes} from "../types/modal";

export const modalState = atom<modalTypes>({
    key: 'modal',
    default: {
        show: false,
        title: '',
        children: '',
        maxWidth: '',
    }
});

export const showModal = (hook: SetterOrUpdater<any>, {title, maxWidth, children}: Pick<modalTypes, 'children' | 'title' | 'maxWidth'>) => {
    hook({
        show: true,
        title,
        maxWidth,
        children,
    });
};

export const closeModal = (hook: SetterOrUpdater<any>) => {
    hook({
        show: false,
        title: '',
        maxWidth: '',
        children: ''
    });
};
