import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import React, {lazy, Suspense, useEffect} from "react";
import {useLoginCheck} from "../../api/user";
import {useSetRecoilState} from "recoil";
import {setUser, userState} from "../../recoil/user";
import {LayoutTemplate} from "../../components/templates/layout";

const Home = lazy(() => import('../home').then((module) => ({default: module.Home})));
const Manage = lazy(() => import('../manage').then((module) => ({default: module.Manage})));
const Recipe = lazy(() => import('../recipe').then((module) => ({default: module.Recipe})));
const Recommend = lazy(() => import('../recommend').then((module) => ({default: module.Recommend})));
const Share = lazy(() => import('../share').then((module) => ({default: module.Share})));
const Shop = lazy(() => import('../shop').then((module) => ({default: module.Shop})));
const ManageCreate = lazy(() => import('../manage/create').then((module) => ({default: module.ManageCreate})));
const ManageEvaluate = lazy(() => import('../manage/evaluate').then((module) => ({default: module.ManageEvaluate})));
const ManageShare = lazy(() => import('../manage/share').then((module) => ({default: module.ManageShare})));
const ManageShareDetail = lazy(() => import('../manage/shareDetail').then((module) => ({default: module.ManageShareDetail})));
const ShareDetail = lazy(() => import('../share/detail').then((module) => ({default: module.ShareDetail})));
const ManagePrint = lazy(() => import('../manage/print').then((module) => ({default: module.ManagePrint})));
const ManageIngredient = lazy(() => import('../manage/ingredient').then((module) => ({default: module.ManageIngredient})));
const ManageRestaurant = lazy(() => import('../manage/restaurant').then((module) => ({default: module.ManageRestaurant})));
const RecipeCreate = lazy(() => import('../recipe/create').then((module) => ({default: module.RecipeCreate})));
const RecipeDetail = lazy(() => import('../recipe/detail').then((module) => ({default: module.RecipeDetail})));
const Login = lazy(() => import('../login').then((module) => ({default: module.Login})));
const Signup = lazy(() => import('../signup').then((module) => ({default: module.Signup})));
const SignupComp = lazy(() => import('../signup/complete').then((module) => ({default: module.SignupComp})));
const FindPw = lazy(() => import('../find/findPw').then((module) => ({default: module.FindPw})));
const RecipeBest = lazy(() => import('../recipe/best').then((module) => ({default: module.RecipeBest})));
const RecipeNew = lazy(() => import('../recipe/new').then((module) => ({default: module.RecipeNew})));
const RecipeMy = lazy(() => import('../recipe/my').then((module) => ({default: module.RecipeMy})));
const MyPage = lazy(() => import('../mypage').then((module) => ({default: module.MyPage})));
const Search = lazy(() => import('../search').then((module) => ({default: module.Search})));
const ManageModify = lazy(() => import('../manage/modify').then((module) => ({default: module.ManageModify})));
const RecipeModify = lazy(() => import('../recipe/modify').then((module) => ({default: module.RecipeModify})));
const Board = lazy(() => import('../board').then((module) => ({default: module.Board})));
const ManageDetail = lazy(() => import('../manage/detail').then((module) => ({default: module.ManageDetail})));

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {mutate} = useLoginCheck();
    const userStateHook = useSetRecoilState(userState);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname === '/login') return;

        mutate(undefined, {
            onSuccess: async ({data}) => {
                setUser(userStateHook, {seq: data.seq, id: data.id, email: data.email, name: data.name, role: data.role});
            },
            onError: async () => {
                // if (location.pathname !== '/login') navigate('/login');
            }
        });
    }, [location]);

    return (
        <Suspense fallback={<LayoutTemplate />}>
            <Routes>
                <Route path="/" element={<Home/>}/>

                <Route path="/search" element={<Search/>}/>

                <Route path="/manage/diet" element={<Manage/>}/>
                <Route path="/manage/diet/:seq" element={<ManageDetail/>}/>
                <Route path="/manage/diet/create" element={<ManageCreate/>}/>
                <Route path="/manage/diet/modify/:seq" element={<ManageModify/>}/>
                <Route path="/manage/diet/print" element={<ManagePrint/>}/>
                <Route path="/manage/diet/evaluate" element={<ManageEvaluate/>}/>
                <Route path="/manage/diet/share" element={<ManageShare/>}/>
                <Route path="/manage/diet/share/:seq" element={<ManageShareDetail/>}/>
                <Route path="/manage/ingredient" element={<ManageIngredient/>}/>
                <Route path="/manage/restaurant" element={<ManageRestaurant/>}/>

                <Route path="/recipe" element={<Recipe/>}/>
                <Route path="/recipe/:seq" element={<RecipeDetail/>}/>
                <Route path="/recipe/create" element={<RecipeCreate/>}/>
                <Route path="/recipe/modify/:seq" element={<RecipeModify/>}/>

                <Route path="/recipe/best" element={<RecipeBest/>}/>
                <Route path="/recipe/new" element={<RecipeNew/>}/>
                <Route path="/recipe/my" element={<RecipeMy/>}/>

                <Route path="/recommend" element={<Recommend/>}/>

                <Route path="/share" element={<Share/>}/>
                <Route path="/share/:seq" element={<ShareDetail/>}/>

                <Route path="/shop" element={<Shop/>}/>

                <Route path="/board" element={<Board/>}/>

                <Route path="/login" element={<Login/>}/>

                <Route path="/signup" element={<Signup/>}/>
                <Route path="/signup/complete" element={<SignupComp/>}/>

                <Route path="/find/pw" element={<FindPw/>}/>

                <Route path="/mypage" element={<MyPage/>}/>
            </Routes>
        </Suspense>
    );
}
