import axios from 'axios';
import {useMutation} from "react-query";
import {useCallback} from "react";
import {userTypes} from "../types/user";

export const useLogin = () => {
    const api = useCallback((data: Pick<userTypes, 'email' | 'password'>) => {
        return axios.post(`/vendor/api/v1/user/login`, data);
    }, []);

    return useMutation(
        async (data: Pick<userTypes, 'email' | 'password'>) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useLogout = () => {
    const api = useCallback(() => {
        return axios.post(`/vendor/api/v1/user/logout`);
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useSignout = () => {
    const api = useCallback(() => {
        return axios.post(`/vendor/api/v1/user/signout`);
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useLoginCheck = () => {
    const api = useCallback(() => {
        return axios.post('/vendor/api/v1/user/login/check');
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useEmailCheck = () => {
    const api = useCallback((data: Pick<userTypes, 'email'>) => {
        return axios.post('/vendor/api/v1/user/email/check', data);
    }, []);

    return useMutation(
        async (data: Pick<userTypes, 'email'>) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useUpdateUser = () => {
    const api = useCallback((data: userTypes) => {
        return axios.put('/vendor/api/v1/user', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useFindUserId = () => {
    const api = useCallback((data: userTypes) => {
        return axios.post('/vendor/api/v1/user/find/id', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useSendEmailCode = () => {
    const api = useCallback((data: userTypes) => {
        return axios.post('/vendor/api/v1/auth/send', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useUserFindPw = () => {
    const api = useCallback((data: userTypes) => {
        return axios.post('/vendor/api/v1/user/find/pw', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertUser = () => {
    const api = useCallback((data: userTypes) => {
        return axios.post('/vendor/api/v1/user/join', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useUpdateUserPassword = () => {
    const api = useCallback((data: userTypes) => {
        return axios.put('/vendor/api/v1/user/password', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}
