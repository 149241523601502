import {atom, SetterOrUpdater} from 'recoil';
import {modalTypes} from "../types/modal";
import {alertLevels, alertTypes} from "../types/alert";

export const alertState = atom<alertTypes>({
    key: 'alert',
    default: {
        show: false,
    }
});

export const showAlert = (hook: SetterOrUpdater<any>, {title, level = alertLevels.normal, onSubmit}: Pick<alertTypes, 'title' | 'level' | 'onSubmit'>) => {
    hook({
        show: true,
        title,
        level,
        onSubmit
    });
};

export const closeAlert = (hook: SetterOrUpdater<any>) => {
    hook({
        show: false,
    });
};
