import styled from "styled-components";
import {MouseEvent, ReactNode, useCallback, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import {userState} from "../../../recoil/user";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {modalState, showModal} from "../../../recoil/modal";
import {useFindPrivacy} from "../../../api/privacy";
import {colors} from "../../../styles/colors";
import {useWindowResize} from "../../../utils/useWindowResize";

export const LayoutTemplate = ({children}: { children?: ReactNode }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {mutate} = useFindPrivacy();
    const {seq, email} = useRecoilValue(userState);
    const setModal = useSetRecoilState(modalState);
    const [width, height, isMobile] = useWindowResize();
    const [fold, setFold] = useState(false);
    const [collapse, setCollapse] = useState('');

    const onPrivacy1Modal = useCallback(() => {
        mutate({type: 1}, {
            onSuccess: ({data}) => {
                showModal(setModal, {title: '이용약관', children: <div dangerouslySetInnerHTML={{__html: data.content}}></div>});
            }
        });
    }, []);

    const onPrivacy2Modal = useCallback(() => {
        mutate({type: 2}, {
            onSuccess: ({data}) => {
                showModal(setModal, {title: '개인정보 처리방침', children: <div dangerouslySetInnerHTML={{__html: data.content}}></div>});
            }
        });
    }, []);

    return (
        <LayoutWrap>
            <LayoutHeaderWrap>
                {
                    !isMobile &&
                    <LayoutHeaderMainWrap>
                        <Logo src={logo} alt={'logo'} onClick={() => navigate('/')}/>

                        <LayoutHeaderCenterWrap>
                            {/*<LayoutHeaderCenterItemWrap onClick={() => navigate('/find')}*/}
                            {/*                            $active={location.pathname === '/find'}>식단검색</LayoutHeaderCenterItemWrap>*/}
                            <LayoutHeaderCenterItemWrap onClick={() => navigate('/recommend')}
                                                        $active={location.pathname === '/recommend'}>맞춤형레시피</LayoutHeaderCenterItemWrap>
                            <LayoutHeaderCenterItemWrap onClick={() => seq ? navigate('/manage/diet') : navigate('/login')}
                                                        $active={location.pathname.indexOf('/manage') !== -1}>식단관리</LayoutHeaderCenterItemWrap>
                            <LayoutHeaderCenterItemWrap onClick={() => navigate('/shop')}
                                                        $active={location.pathname === '/shop'}>회원가쇼핑</LayoutHeaderCenterItemWrap>
                            <LayoutHeaderCenterItemWrap onClick={() => navigate('/recipe')}
                                                        $active={location.pathname.indexOf('/recipe') !== -1}>레시피</LayoutHeaderCenterItemWrap>
                            <LayoutHeaderCenterItemWrap onClick={() => navigate('/share')}
                                                        $active={location.pathname === '/share'}>식단공유</LayoutHeaderCenterItemWrap>
                            <LayoutHeaderCenterItemWrap onClick={() => navigate('/board')}
                                                        $active={location.pathname === '/board'}>정보게시판</LayoutHeaderCenterItemWrap>
                        </LayoutHeaderCenterWrap>

                        <LayoutHeaderRightWrap>
                            <LayoutHeaderSignWrap>
                                <li onClick={() => navigate(seq ? '/mypage' : '/login')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
                                         stroke="currentColor"
                                         strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user">
                                        <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/>
                                        <circle cx="12" cy="7" r="4"/>
                                    </svg>
                                </li>
                            </LayoutHeaderSignWrap>
                        </LayoutHeaderRightWrap>
                    </LayoutHeaderMainWrap>
                }

                {
                    isMobile &&
                    <>
                        <LayoutHeaderMainWrap>
                            <svg onClick={() => setFold(true)}
                                 xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M26 1.42857C26 2.21755 25.3604 2.85714 24.5714 2.85714H1.42857C0.639593 2.85714 0 2.21755 0 1.42857C0 0.639593 0.639593 0 1.42857 0H24.5714C25.3604 0 26 0.639593 26 1.42857Z"
                                      fill="#8E9AA5"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M26 9.99998C26 10.789 25.3604 11.4286 24.5714 11.4286H1.42857C0.639593 11.4286 0 10.789 0 9.99998C0 9.211 0.639593 8.57141 1.42857 8.57141H24.5714C25.3604 8.57141 26 9.211 26 9.99998Z"
                                      fill="#8E9AA5"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M26 18.5714C26 19.3604 25.3604 20 24.5714 20H1.42857C0.639593 20 0 19.3604 0 18.5714C0 17.7824 0.639593 17.1428 1.42857 17.1428H24.5714C25.3604 17.1428 26 17.7824 26 18.5714Z"
                                      fill="#8E9AA5"/>
                            </svg>

                            <Logo src={logo} alt={'logo'} onClick={() => navigate('/')}/>
                        </LayoutHeaderMainWrap>

                        {
                            fold &&
                            <LayoutHeaderSubWrap onClick={(e: MouseEvent<HTMLDivElement>) => {
                                const target = e.target as HTMLDivElement;
                                if (target.className.indexOf('collapse') === -1) setFold(false);
                            }}>
                                <LayoutHeaderSubTopWrap>
                                    <Logo src={logo} alt={'logo'} onClick={() => navigate('/')}/>
                                </LayoutHeaderSubTopWrap>

                                <LayoutHeaderSubMidWrap>
                                    <LayoutHeaderSubItemWrap onClick={() => {
                                        navigate('/recommend');
                                    }}
                                                             $active={location.pathname === '/recommend'}
                                    >
                                        맞춤형레시피
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M16.8166 14L10 7.55907L10.5917 7L18 14L10.5917 21L10 20.4409L16.8166 14Z" fill="#6A7584"/>
                                        </svg>
                                    </LayoutHeaderSubItemWrap>

                                    <LayoutHeaderSubItemWrap
                                        className={'collapse'}
                                        onClick={() => setCollapse((prev) => prev === '식단관리' ? '' : '식단관리')}
                                        $active={location.pathname.indexOf('/manage') !== -1}
                                    >
                                        식단관리
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M16.8166 14L10 7.55907L10.5917 7L18 14L10.5917 21L10 20.4409L16.8166 14Z" fill="#6A7584"/>
                                        </svg>
                                    </LayoutHeaderSubItemWrap>

                                    <LayoutHeaderSubChildWrap $show={collapse === '식단관리'}>
                                        <LayoutHeaderSubChildItemWrap onClick={() => navigate('/manage/diet')}>식단 조회</LayoutHeaderSubChildItemWrap>
                                        <LayoutHeaderSubChildItemWrap onClick={() => navigate('/manage/diet/create')}>식단 등록</LayoutHeaderSubChildItemWrap>
                                        <LayoutHeaderSubChildItemWrap onClick={() => navigate('/manage/diet/evaluate')}>식단 평가</LayoutHeaderSubChildItemWrap>
                                        <LayoutHeaderSubChildItemWrap onClick={() => navigate('/manage/diet/share')}>공유 식단</LayoutHeaderSubChildItemWrap>
                                        <LayoutHeaderSubChildItemWrap onClick={() => navigate('/manage/diet/board')}>정보 게시판</LayoutHeaderSubChildItemWrap>
                                    </LayoutHeaderSubChildWrap>

                                    <LayoutHeaderSubItemWrap onClick={() => navigate('/shop')} $active={location.pathname === '/shop'}>
                                        회원가쇼핑
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M16.8166 14L10 7.55907L10.5917 7L18 14L10.5917 21L10 20.4409L16.8166 14Z" fill="#6A7584"/>
                                        </svg>
                                    </LayoutHeaderSubItemWrap>

                                    <LayoutHeaderSubItemWrap onClick={() => navigate('/recipe')} $active={location.pathname === '/recipe'}>
                                        레시피
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M16.8166 14L10 7.55907L10.5917 7L18 14L10.5917 21L10 20.4409L16.8166 14Z" fill="#6A7584"/>
                                        </svg>
                                    </LayoutHeaderSubItemWrap>

                                    <LayoutHeaderSubItemWrap onClick={() => navigate('/share')} $active={location.pathname === '/share'}>
                                        식단공유
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M16.8166 14L10 7.55907L10.5917 7L18 14L10.5917 21L10 20.4409L16.8166 14Z" fill="#6A7584"/>
                                        </svg>
                                    </LayoutHeaderSubItemWrap>
                                </LayoutHeaderSubMidWrap>

                                <LayoutHeaderSubBottomWrap>
                                    <LayoutHeaderSubBottomItemWrap>
                                        {
                                            seq ?
                                                <>
                                                    <span onClick={() => navigate('/mypage')}>마이페이지</span>
                                                </>
                                                :
                                                <>
                                                    <span onClick={() => navigate('/login')}>로그인</span>
                                                    <span>|</span>
                                                    <span onClick={() => navigate('/signup')}>회원가입</span>
                                                </>
                                        }
                                    </LayoutHeaderSubBottomItemWrap>
                                </LayoutHeaderSubBottomWrap>
                            </LayoutHeaderSubWrap>
                        }
                    </>
                }
            </LayoutHeaderWrap>

            <LayoutContentWrap>
                {children}
            </LayoutContentWrap>

            <LayoutFooterWrap>
                <LayoutFooterLeftWrap>
                    <img src={logo} alt={'logo'}/>
                </LayoutFooterLeftWrap>

                <LayoutFooterMiddleWrap>
                    <ul>
                        <span onClick={onPrivacy2Modal}>개인정보처리방침</span>
                        <li>|</li>
                        <span onClick={onPrivacy1Modal}>이용약관</span>
                    </ul>

                    <h2>회사정보</h2>

                    <ul>
                        <li>회사명 | (주) 레시핏</li>
                        <li>대표명 | 정종찬</li>
                        <li>사업자번호 | 179-86-01842</li>
                    </ul>
                    <ul>
                        <li>주소 | 경기도 수원시 영통구 광교로 156, 601호 (AI랩)</li>
                        <li>이메일 | recifit@naver.com</li>
                    </ul>

                    <ul>
                        <li>Copyright © RECIFIT All Rights Reserved.</li>
                    </ul>
                </LayoutFooterMiddleWrap>
                <LayoutFooterRightWrap>
                    <ul>
                        <li>고객센터</li>
                        <li>0507-1328-1623</li>
                    </ul>
                </LayoutFooterRightWrap>
            </LayoutFooterWrap>
        </LayoutWrap>
    )
};

const LayoutWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const Logo = styled.img`
  max-width: 100px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 80px;
    position: absolute;
    left: calc(50% - 40px);
  }
`

const LayoutHeaderWrap = styled.div`
  box-shadow: rgb(0 0 0 / 19%) 0 3px 4px 0;
  position: relative;
  z-index: 1;
`

const LayoutHeaderMainWrap = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 40px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`

const LayoutHeaderSubWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 70vw;
  height: 100vh;
  background-color: #fff;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -30vw;
    width: 30vw;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.7);
  }

  & > svg {
    cursor: pointer;
    position: fixed;
    top: 15px;
    right: 20px;
  }
`

const LayoutHeaderSubTopWrap = styled.div`
  height: 100px;
  background-color: #5BAEB7;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LayoutHeaderSubMidWrap = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const LayoutHeaderSubBottomWrap = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const LayoutHeaderSubBottomItemWrap = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin: 0 auto;
  gap: 40px;
`

const LayoutHeaderSubItemWrap = styled.div<{ $active?: boolean }>`
  color: ${({$active}) => $active && colors.main};
  padding: 20px 10px;
  border-bottom: 1px solid #DEE3E8;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LayoutHeaderSubChildWrap = styled.div<{ $show: boolean }>`
  background-color: #F3F4F6;
  display: flex;
  visibility: ${({$show}) => $show ? 'visible' : 'hidden'};
  height: ${({$show}) => $show ? 'auto' : 0};
  padding: ${({$show}) => $show ? '15px' : 0};
  opacity: ${({$show}) => $show ? 1 : 0};
  transition: visibility 0s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.4s ease-in-out 0s;
  flex-direction: column;
  gap: 15px;
  font-size: 14px;
`

const LayoutHeaderSubChildItemWrap = styled.div`

`

const LayoutHeaderCenterWrap = styled.ul`
  display: flex;
  gap: 50px;
  font-weight: 500;

  & li {
    cursor: pointer;
  }
`

const LayoutHeaderCenterItemWrap = styled.li<{ $active?: boolean, $hover?: boolean }>`
  color: ${({$active}) => $active && colors.main};
  border-bottom: ${({$active}) => $active && `2px solid ${colors.main}`};
  padding: 5px 0;
  position: relative;
`

const LayoutHeaderRightWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
`

const LayoutHeaderSignWrap = styled.ul`
  display: flex;
  gap: 30px;

  & li {
    cursor: pointer;
  }
`

const LayoutContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 700px;
`

const LayoutFooterWrap = styled.div`
  background-color: ${colors.main};
  color: ${colors.gray['030']};
  display: flex;
  padding: 40px;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    font-size: 12px;
  }
`

const LayoutFooterLeftWrap = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 100%;
    max-width: 130px;
  }
`

const LayoutFooterMiddleWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;

  & ul {
    display: flex;
    gap: 10px;
  }

  & ul span {
    cursor: pointer;
  }

  & ul:nth-child(4) {
    flex-direction: column;
  }

  & > h2 {
    padding-top: 10px;
  }

  & > ul:last-child {
    padding-top: 10px;
  }

`

const LayoutFooterRightWrap = styled.div`
  width: 30%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  & ul {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media screen and (max-width: 768px) {
      align-items: center;
      flex-direction: row;
    }
  }

  & ul > li:nth-child(2) {
    font-size: 28px;
    font-weight: bold;
    padding: 4px 0;
  }
`
