import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from "./pages/app";
import axios from "axios";
import {BASE_URL, LOCAL_BASE_URL} from "./constants/setting";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {RecoilRoot} from "recoil";
import Modal from "./components/atoms/modal";
import Alert from "./components/atoms/alert";

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? BASE_URL : LOCAL_BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    request => {
        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status !== 401) {
            const {message} = error.response.data;
            alert(message);
        }

        return Promise.reject(error);
    }
);

const queryClient = new QueryClient({
    defaultOptions: {queries: {refetchOnWindowFocus: false, retry: 1}},
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter>
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <App/>
                <Modal/>
                <Alert/>
            </QueryClientProvider>
        </RecoilRoot>
    </BrowserRouter>
);
