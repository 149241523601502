import {useEffect, useState} from "react";
import {useDebounceFn} from "./useDebounceFn";

export const useWindowResize = () => {
    const [windowSize, setWindowSize] = useState<{ width: undefined | number, height: undefined | number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = useDebounceFn(() => setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
    }), 500);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        } else {
            return () => window.removeEventListener("resize", () => {
                return null
            });
        }
    }, []);

    return [windowSize.width, windowSize.height, (windowSize.width && windowSize.width <= 768)];
}
