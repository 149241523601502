import styled from 'styled-components';
import {ReactNode} from "react";
import {shapes, sizes} from "../../../types";
import {Spinner1, Spinner2} from "../../../styles/component";
import {fontStyles, typography} from '../../../styles/typography';
import {colors} from "../../../styles/colors";

type props = {
    children?: ReactNode;
    width?: string;
    flex?: number;
    theme?: buttonThemes;
    size?: sizes;
    shape?: shapes;
    fontStyle?: fontStyles;
    $isAble?: boolean;
    $isLoading?: boolean;
    onClick?(): void;
}

type styledTypes = Pick<props, 'width' | 'theme' | 'flex' | 'fontStyle' | 'shape' | 'size' | '$isAble'>;

export const buttonThemes = {
    normal: 'normal',
    black: 'black',
    border: 'border',
    danger: 'danger',
    text: 'text'
} as const;
export type buttonThemes = typeof buttonThemes[keyof typeof buttonThemes];

const Button = ({children, width, theme, shape, flex, $isAble = true, $isLoading, fontStyle, size, onClick}: props) => {
    return (
        <SizeButtonWrap type={'button'} width={width} theme={theme} shape={shape} flex={flex} $isAble={$isAble} fontStyle={fontStyle}
                        onClick={($isAble && !$isLoading) ? onClick : () => {}} size={size}>
            {$isLoading ? <Spinner2/> : children}
        </SizeButtonWrap>
    )
}

const ButtonWrap = styled.button<styledTypes>`
  ${({fontStyle}) => (fontStyle ? typography[fontStyle] : '')};
  ${({flex}) => (flex ? `flex: ${flex}` : '')};
  ${({width}) => (width ? `width: ${width}` : '')};
  opacity: ${({$isAble}) => $isAble ? 1 : 0.3};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  
  transition: all 0.3s;

  ${({$isAble}) => $isAble && `
    &:hover {
        opacity: 0.8;
    }
  `}
`;

const ThemeButtonWrap = styled(ButtonWrap)`
  ${({theme}) => {
    switch (theme) {
      case buttonThemes.text:
        return `
            text-decoration: underline;
          `;
      case buttonThemes.border:
        return `
            border: 1px solid ${colors.gray["050"]};
            color: ${colors.black};
          `;
      case buttonThemes.black:
        return `
            color: ${colors.white};
            background-color: ${colors.black};
          `;
      case buttonThemes.danger:
        return `
            color: ${colors.white};
            background-color: ${colors.red["060"]}  
          `;
      default:
        return `
            background-color: ${colors.main};
            color: #fff;
        `;
    }
  }};
`

const ShapeButtonWrap = styled(ThemeButtonWrap)`
  ${({shape}) => {
    switch (shape) {
      case shapes.circle:
        return `
            border-radius: 100%;
          `
      default:
        return `
            border-radius: 6px;
        `;
    }
  }};
`

const SizeButtonWrap = styled(ShapeButtonWrap)`
  ${({size}) => {
    switch (size) {
      case sizes.xxs:
        return `
            height: 30px;
          `
      case sizes.xs:
        return `
            height: 35px;
          `
      case sizes.sm:
        return `
            height: 40px;
          `
      case sizes.md:
        return `
            height: 45px;
          `
      case sizes.lg:
        return `
            height: 50px;
          `
      default:
        return `
            height: 40px;
        `;
    }
  }};
`

export default Button;
