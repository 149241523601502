import {useCallback, useRef} from "react";

export const useDebounceFn = (callback: () => void, delay = 500) => {
    const timer: { current: NodeJS.Timer | null } = useRef(null);

    return useCallback(() => {
        clearTimeout(timer.current as NodeJS.Timer);
        timer.current = null;
        timer.current = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            if (timer.current) clearTimeout(timer.current as NodeJS.Timer);
        }
    }, []);
};
