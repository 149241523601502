import {atom, SetterOrUpdater} from 'recoil';
import {userTypes} from "../types/user";

export const userState = atom<userTypes>({
    key: 'user',
    default: {
        seq: '',
        id: '',
        email: '',
        name: '',
        role: '',
    }
});

export const setUser = (hook: SetterOrUpdater<any>, {seq, id, email, name, role}: Pick<userTypes, 'seq' | 'id' | 'email' | 'name' | 'role'>) => {
    hook({
        seq,
        id,
        email,
        name,
        role,
    });
};

export const deleteUser = (hook: SetterOrUpdater<any>) => {
    hook({
        seq: '',
        id: '',
        email: '',
        name: '',
        role: '',
    });
};
