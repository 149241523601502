export type alertTypes = {
    show: boolean;
    title?: string;
    level?: alertLevels;
    onSubmit?(): void;
}

export const alertLevels = {
    normal: 'normal',
    warning: 'warning',
    danger: 'danger'
} as const;
export type alertLevels = typeof alertLevels[keyof typeof alertLevels];
