import axios from 'axios';
import {useMutation} from "react-query";
import {useCallback} from "react";
import {privacyTypes} from "../types/privacy";

export const useFindPrivacy = () => {
    const api = useCallback(({type}: privacyTypes) => {
        return axios.get(`/vendor/api/v1/privacy/${type}`);
    }, []);

    return useMutation(
        ['privacy'],
        async ({type}: privacyTypes) => {
            const response = await api({type});
            return response.data;
        }
    );
}
